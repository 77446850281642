import type { NavLinkProps} from '@remix-run/react';
import { NavLink } from '@remix-run/react';
import classNames from 'classnames';

export default function PageNavLink({
  to,
  className,
  children,
  icon,
  isSubNavLink,
  ...props
}: NavLinkProps & { icon?: React.ReactNode; isSubNavLink?: boolean }) {
  if (!to) return null;
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          'flex items-center text-xl font-bold border-b-4 pb-[4px] sm:pb-[12px]',
          {
            'text-white no-underline border-b-theme-cyan-light': isActive,
            'no-underline border-b-transparent hover:border-b-theme-cyan-light hover:text-white':
              !isActive,
            'text-slate-400': !isActive && !isSubNavLink,
            'text-white': !isActive && isSubNavLink,
          },
          className
        )
      }
      prefetch="intent"
      {...props}
    >
      {icon && <span className="mr-1.5">{icon}</span>}
      <span className="whitespace-nowrap">{children}</span>
    </NavLink>
  );
}
